import React from 'react';
import ImageGallery from 'react-image-gallery';
import { isBrowser } from "react-device-detect";
import "react-image-gallery/styles/css/image-gallery.css";
import "../css/FamilyPhotos.css";

const _images = [
  {
    "original": "/images/foxfamold.JPG",
    "textHeader": "The Fox Family",
    //"text": "THIS IS A LOT MORE TEXR FOR THE SECOND IMAGE. MAKE SURE THAT IT LOOKS RIGHT ON A PHONE IF IT IS SEVERAL LINES OR MORE BECAUSE WE NEED TO CHECK",
  },
  {
    "original": "/images/photos/belanger_family.jpg",
    "textHeader": "The Belanger Family",
    //"text": "THIS IS A LOT MORE TEXR FOR THE SECOND IMAGE. MAKE SURE THAT IT LOOKS RIGHT ON A PHONE IF IT IS SEVERAL LINES OR MORE BECAUSE WE NEED TO CHECK",
  },
  {
    "original": "/images/disney_grampie.jpg",
    //"textHeader": "Kristen's Grandparents",
    //"text": "THIS IS A LOT MORE TEXR FOR THE SECOND IMAGE. MAKE SURE THAT IT LOOKS RIGHT ON A PHONE IF IT IS SEVERAL LINES OR MORE BECAUSE WE NEED TO CHECK",
  },
  {
    "original": "/images/mr_b_and_parents.jpg",
    //"textHeader": "Memere and ",
    //"text": "THIS IS A LOT MORE TEXR FOR THE SECOND IMAGE. MAKE SURE THAT IT LOOKS RIGHT ON A PHONE IF IT IS SEVERAL LINES OR MORE BECAUSE WE NEED TO CHECK",
  },
]

class FamilyPhotos extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      textHeader: _images[0].textHeader,
      text: _images[0].text,
      height: 0
    };
  }

  componentDidMount() {
    const height = this.textElement.clientHeight;
    this.setState({ height });
  }

  onSlide = () => {
    var idx = this._imageGallery.getCurrentIndex()
    console.log('Slid to ' + idx);
    console.log('Text: ' + _images[idx].text);
    this.setState({textHeader: _images[idx].textHeader})
    this.setState({text: _images[idx].text})
  }

  render() {
    const textStyle = {
      textAlign: isBrowser ? "center" : "left"
    }
    return (
      <div>
        <ImageGallery 
          ref = { (i) => this._imageGallery = i}
          lazyLoad = {true} 
          showThumbnails = {false}
          showPlayButton={false}
          showFullscreenButton={false}
          items = {_images} 
          onImageLoad = {this.onImageLoad} 
          onSlide = {this.onSlide}
        />
        <div className="image-text-header">
          {this.state.textHeader}
        </div>
        <p><br></br></p>
        <div 
          className="image-text" 
          ref={ (div) => this.textElement = div}
          style={textStyle}
        >
          {this.state.text}
        </div>
      </div>
    );
  }
}

export default FamilyPhotos;
