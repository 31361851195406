import React from 'react';
import "../css/Contacts.css";

class Contacts extends React.Component {

  render() {
    return (
      <div>
        <div className="contacts-image-wrapper">
          <img src="/images/anyone_but.jpg" className="static-page-image" />
        </div>
        <div className="contacts-header" >
          For any additional details, please contact any of the following: 
        </div>
        <div className="contacts-list">
          <h3>Diane Belanger</h3>
          <p>Mother of the Bride</p>
          <p>dianembelanger19@gmail.com</p>
          <a href="tel:508-843-4098">508-843-4098</a>

          <h3>Jen Belanger</h3>
          <p>Maid of Honor</p>
          <p>jneb1807@gmail.com</p>
          <a href="tel:978-430-2812">978-430-2812</a>

          <h3>Audrey Boyer</h3>
          <p>Maid of Honor</p>
          <p>audreyboyer621@gmail.com</p>
          <a href="tel:978-857-5420">978-857-5420</a>

          <h3>Michelle Fox</h3>
          <p>Mother of the Groom</p>
          <p>michelle.fox200@gmail.com</p>
          <a href="tel:978-828-5880">978-828-5880</a>
          <p><br></br></p>
        </div>
      </div>
    );
  }
}

export default Contacts;
