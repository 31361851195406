import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom'
// sort of worked..
import  MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
// import { createMuiTheme } from '@material-ui/core/es/styles';

// import { ThemeProvider } from '@material-ui/styles';
// import { createMuiTheme } from '@material-ui/core/styles';

// import { MuiThemeProvider } from '@material-ui/core/styles';
// import { createMuiTheme } from '@material-ui/core/styles';

// import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// const theme = createMuiTheme({
//   palette: {
//     primary: {
//       main: '#030b1f'
//     },
//     secondary: {
//       main: '#030b1f'
//     }
//   }
// })

ReactDOM.render(
  <BrowserRouter>
    {/* <OldProvider> */}
      {/* <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider> */}
    {/* </OldProvider> */}
    {/* <ThemeProvider muiTheme={theme}>
      <App />
    </ThemeProvider> */}
    <MuiThemeProvider>
      <App />
    </MuiThemeProvider>
  </BrowserRouter>
, document.getElementById('root'));
