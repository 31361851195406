import React from 'react';
import "../css/Accommodations.css";

class Accommodations extends React.Component {

  render() {
    const attireDetailsStyle = {
      margin: '10px', 
      borderBottom: '10px solid', 
      borderColor: '#030b1f'
    }
  
    return (
      <div>
        <div style={attireDetailsStyle}>
          <div className="accommodations-image-wrapper">
            <img src="/images/wylie_inn.jpg" className="static-page-image" />
          </div>
          <div className="accommodations-details" >
            <h2>Accommodations</h2>
            <p>
              We have reserved a block of rooms at the Wylie Inn Hotel and Conference Center, located directly next door to the venue.
            </p>
            <p>
              Please contact Tupper Manor or Wylie Inn and ask for Colby Magliaro to receive the special rate.
            </p>
            <p>
              If booked outside of the room block via the website, regular rates will apply. 
            </p>
            <h4>Tupper Manor</h4>
            <a href="tel:978-867-1800">978-867-1800</a>
            <h4>Wylie Inn</h4>
            <a href="tel:866-333-0859">866-333-0859</a>
            <p><br></br></p>
          </div>
        </div>
        <div className="accommodations-details" >
          <h2>Attire</h2>
          <p>
            Cocktail/Semi-formal
          </p>
          <p><br></br></p>
        </div>
        <div className="accommodations-image-wrapper">
          <img src="/images/dont-worry-be-happy.jpg" className="static-page-image" />
        </div>
        <p><br></br></p>
      </div>
    );
  }
}

export default Accommodations;
