import React from 'react';
import ImageGallery from 'react-image-gallery';
import { isBrowser } from "react-device-detect";
import "react-image-gallery/styles/css/image-gallery.css";
import "../css/WeddingParty.css";

const _images = [
  {
    "original": "/images/photos/bachelorette_1.jpeg",
    "textHeader": "Meet the Bridal Party!",
    //"text": "THIS IS A LOT MORE TEXR FOR THE SECOND IMAGE. MAKE SURE THAT IT LOOKS RIGHT ON A PHONE IF IT IS SEVERAL LINES OR MORE BECAUSE WE NEED TO CHECK",
  },
  {
    "original": "/images/wedding_party/jen_and_kristen.jpg",
    "textHeader": "Jennifer Belanger - Maid of Honor",
    //"text": "THIS IS A LOT MORE TEXR FOR THE SECOND IMAGE. MAKE SURE THAT IT LOOKS RIGHT ON A PHONE IF IT IS SEVERAL LINES OR MORE BECAUSE WE NEED TO CHECK",
  },
  {
    "original": "/images/wedding_party/audrey_and_kristen.jpg",
    "textHeader": "Audrey Boyer - Maid of Honor",
    //"text": "THIS IS A LOT MORE TEXR FOR THE SECOND IMAGE. MAKE SURE THAT IT LOOKS RIGHT ON A PHONE IF IT IS SEVERAL LINES OR MORE BECAUSE WE NEED TO CHECK",
  },
  {
    "original": "/images/wedding_party/lauren.JPG",
    "textHeader": "Lauren House - Bridesmaid",
    //"text": "THIS IS A LOT MORE TEXR FOR THE SECOND IMAGE. MAKE SURE THAT IT LOOKS RIGHT ON A PHONE IF IT IS SEVERAL LINES OR MORE BECAUSE WE NEED TO CHECK",
  },
  {
    "original": "/images/wedding_party/aurora.jpg",
    "textHeader": "Aurora Christo - Bridesmaid",
    //"text": "THIS IS A LOT MORE TEXR FOR THE SECOND IMAGE. MAKE SURE THAT IT LOOKS RIGHT ON A PHONE IF IT IS SEVERAL LINES OR MORE BECAUSE WE NEED TO CHECK",
  },
  {
    "original": "/images/wedding_party/logan.jpg",
    "textHeader": "Logan Carabello - Bridesmaid",
    //"text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/wedding_party/beth.JPG",
    "textHeader": "Beth Ramsdell - Bridesmaid",
   // "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/wedding_party/samantha.JPG",
    "textHeader": "Samantha Fox - Bridesmaid",
  // "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/wedding_party/kim.jpg",
    "textHeader": "Kim Wright- Bridesmaid",
   // "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/wedding_party/caity.jpg",
    "textHeader": "Caitlyn Fox - Bridesmaid",
   // "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/wedding_party/ashley.jpg",
    "textHeader": "Ashley Coutinho - Bridesmaid",
  //  "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/wedding_party/jenna.jpg",
    "textHeader": "Jenna Apgar - Bridesmaid",
   // "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/photos/bachelor_party.jpg",
    "textHeader": "Meet the Groomsmen!",
   // "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/wedding_party/adam.jpg",
    "textHeader": "Adam Frocione - Best Man",
   // "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/wedding_party/sean_joey.jpg",
    "textHeader": "Joey Frisch - Groomsman",
   // "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/wedding_party/paul_shark.jpg",
    "textHeader": "Paul Kelly - Groomsman",
   // "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
  {
    "original": "/images/wedding_party/daniel_thinking.jpg",
    "textHeader": "Daniel Fox - Groomsman",
   // "text": "SOME TEXT FOR THE FIRST IMAGE. test"
  },
]

class PhotoGallery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      textHeader: _images[0].textHeader,
      text: _images[0].text,
      height: 0
    };
  }

  componentDidMount() {
    const height = this.textElement.clientHeight;
    this.setState({ height });
  }

  onSlide = () => {
    var idx = this._imageGallery.getCurrentIndex()
    console.log('Slid to ' + idx);
    console.log('Text: ' + _images[idx].text);
    this.setState({textHeader: _images[idx].textHeader})
    this.setState({text: _images[idx].text})
  }

  render() {
    const textStyle = {
      textAlign: isBrowser ? "center" : "left"
    }
    return (
      <div>
        <ImageGallery 
          ref = { (i) => this._imageGallery = i}
          lazyLoad = {true} 
          showThumbnails = {false}
          showPlayButton={false}
          showFullscreenButton={false}
          items = {_images} 
          onImageLoad = {this.onImageLoad} 
          onSlide = {this.onSlide}
        />
        <div className="image-text-header">
          {this.state.textHeader}
        </div>
        <p><br></br></p>
        <div 
          className="image-text" 
          ref={ (div) => this.textElement = div}
          style={textStyle}
        >
          {this.state.text}
        </div>
      </div>
    );
  }
}

export default PhotoGallery;
