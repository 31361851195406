
import React from 'react';
import "../css/GoogleForm.css";

const RsvpPage = () => (
  <div className="formWrapper">
    {/* 
      - Form is in the skfox2019 gmail account
    */}
    <iframe 
      className="googleForm"
      src="https://docs.google.com/forms/d/e/1FAIpQLScBQ6rQ38UBIXvtaduadthg6YDI6MDFbcowvQRIPW5DzcRi2g/viewform?embedded=true" 
      height="1600" frameborder="0" marginheight="0" marginwidth="0">
        Loading...
    </iframe>
  </div>
)

export default RsvpPage;

