import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "../css/PhotoGallery.css";

const _images = [
  {
    "original": "/images/falls.JPG",
    "thumbnail": "/images/falls.JPG",
    "downloadName": "falls.jpg"
  },
  {
    "original": "/images/photos/sean_kristen_hawaii.jpg",
    "thumbnail": "/images/photos/sean_kristen_hawaii.jpg",
    "downloadName": "sean_kristen_hawaii.jpg"
  },
  // {
  //   "original": "/images/photos/trees.JPG",
  //   "thumbnail": "/images/photos/trees.JPG",
  //   "downloadName": "trees.jpg"
  // },
  {
    "original": "/images/photos/beers_matterhorn.jpg",
    "thumbnail": "/images/photos/beers_matterhorn.jpg",
    "downloadName": "beers_matterhorn.jpg"
  },
  {
    "original": "/images/photos/prom_us.jpg",
    "thumbnail": "/images/photos/prom_us.jpg",
    "downloadName": "prom_us.jpg"
  },
  {
    "original": "/images/halloween_original.jpg",
    "thumbnail": "/images/halloween_original.jpg",
    "downloadName": "halloween.jpg"
  },
  {
    "original": "/images/colesseum.JPG",
    "thumbnail": "/images/colesseum.JPG",
    "downloadName": "rome.jpg"
  },
  {
    "original": "/images/grad.JPG",
    "thumbnail": "/images/grad.JPG",
    "downloadName": "grad.jpg"
  },
  {
    "original": "/images/photos/ponte_vecchio.JPG",
    "thumbnail": "/images/photos/ponte_vecchio.JPG",
    "downloadName": "ponte_vecchio.jpg"
  },
  {
    "original": "/images/patriots.JPG",
    "thumbnail": "/images/patriots.JPG",
    "downloadName": "patriots.jpg"
  },
  {
    "original": "/images/mickey.JPG",
    "thumbnail": "/images/mickey.JPG",
    "downloadName": "mickey.jpg"
  },
  // {
  //   "original": "/images/photos/enagement_walking.JPG",
  //   "thumbnail": "/images/photos/engagement_walking.JPG",
  //   "downloadName": "engagement_walking.jpg"
  // },
  {
    "original": "/images/splash.JPG",
    "thumbnail": "/images/splash.JPG",
    "downloadName": "splash.jpg"
  },
  {
    "original": "/images/sterling.jpg",
    "thumbnail": "/images/sterling.jpg",
    "downloadName": "sterling.jpg"
  },
  {
    "original": "/images/photos/belanger_family_deer_isle.jpg",
    "thumbnail": "/images/photos/belanger_family_deer_isle.jpg",
    "downloadName": "belanger_family_deer_isle.jpg"
  },
  {
    "original": "/images/photos/bridal_shower.jpg",
    "thumbnail": "/images/photos/bridal_shower.jpg",
    "downloadName": "bridal_shower.jpg"
  },
  {
    "original": "/images/photos/endicott_graduation_nurses.jpg",
    "thumbnail": "/images/photos/endicott_graduation_nurses.jpg",
    "downloadName": "endicott_graduation_nurses.jpg"
  },
  {
    "original": "/images/photos/florence_1.JPG",
    "thumbnail": "/images/photos/florence_1.JPG",
    "downloadName": "florence_1.jpg"
  },
  {
    "original": "/images/photos/florence_2.JPG",
    "thumbnail": "/images/photos/florence_2.JPG",
    "downloadName": "florence_2.jpg"
  },
  {
    "original": "/images/photos/fox_family_hawaii.jpg",
    "thumbnail": "/images/photos/fox_family_hawaii.jpg",
    "downloadName": "fox_family_hawaii.jpg"
  },
  {
    "original": "/images/photos/fox_family_pinning_ceremony.jpg",
    "thumbnail": "/images/photos/fox_family_pinning_ceremony.jpg",
    "downloadName": "fox_family_pinning_ceremony.jpg"
  },
  {
    "original": "/images/photos/high_school.JPG",
    "thumbnail": "/images/photos/high_school.JPG",
    "downloadName": "high_school.jpg"
  },
  {
    "original": "/images/photos/kristen_ashley.JPG",
    "thumbnail": "/images/photos/kristen_ashley.JPG",
    "downloadName": "kristen_ashley.jpg"
  },
  {
    "original": "/images/photos/kristen_audrey.jpeg",
    "thumbnail": "/images/photos/kristen_audrey.jpeg",
    "downloadName": "kristen_audrey.jpg"
  },
  {
    "original": "/images/photos/kristen_kim_jenna.JPG",
    "thumbnail": "/images/photos/kristen_kim_jenna.JPG",
    "downloadName": "kristen_kim_jenna.jpg"
  },
  {
    "original": "/images/photos/kristen_lauren_logan.JPG",
    "thumbnail": "/images/photos/kristen_lauren_logan.JPG",
    "downloadName": "kristen_lauren_logan.jpg"
  },
  {
    "original": "/images/photos/kristen_lauren_prom.JPG",
    "thumbnail": "/images/photos/kristen_lauren_prom.JPG",
    "downloadName": "kristen_lauren_prom.jpg"
  },
  {
    "original": "/images/photos/kristen_vatican_maps_room.JPG",
    "thumbnail": "/images/photos/kristen_vatican_maps_room.JPG",
    "downloadName": "kristen_vatican_maps_room.jpg"
  },
  // {
  //   "original": "/images/photos/lake_italy.JPG",
  //   "thumbnail": "/images/photos/lake_italy.JPG",
  //   "downloadName": "lake_italy.jpg"
  // },
  // {
  //   "original": "/images/photos/matterhorn_1.JPG",
  //   "thumbnail": "/images/photos/matterhorn_1.JPG",
  //   "downloadName": "matterhorn_1.jpg"
  // },
  {
    "original": "/images/photos/matterhorn_night.JPG",
    "thumbnail": "/images/photos/matterhorn_night.JPG",
    "downloadName": "matterhorn_night.jpg"
  },
  {
    "original": "/images/photos/ossipee_1.jpg",
    "thumbnail": "/images/photos/ossipee_1.jpg",
    "downloadName": "ossipee_1.jpg"
  },
  {
    "original": "/images/photos/pats_game.jpg",
    "thumbnail": "/images/photos/pats_game.jpg",
    "downloadName": "pats_game.jpg"
  },
  {
    "original": "/images/photos/prom.JPG",
    "thumbnail": "/images/photos/prom.JPG",
    "downloadName": "prom.jpg"
  },
  {
    "original": "/images/photos/sean_fish.jpg",
    "thumbnail": "/images/photos/sean_fish.jpg",
    "downloadName": "sean_fish.jpg"
  },
  {
    "original": "/images/wedding_party/sean_joey_paul.jpg",
    "thumbnail": "/images/wedding_party/sean_joey_paul.jpg",
    "downloadName": "sean_joey_paul.jpg"
  },
  {
    "original": "/images/photos/sean_kristen.JPG",
    "thumbnail": "/images/photos/sean_kristen.JPG",
    "downloadName": "sean_kristen.jpg"
  },
  {
    "original": "/images/photos/sean_matterhorn.JPG",
    "thumbnail": "/images/photos/sean_matterhorn.JPG",
    "downloadName": "sean_matterhorn.jpg"
  }
]

class PhotoGallery extends React.Component {

  download = () => {
    var idx = this._imageGallery.getCurrentIndex()
    var link = document.createElement('a');
    link.href = _images[idx].original;
    link.download = _images[idx].downloadName;
    document.body.appendChild(link);
    link.click();
  }

  _renderDownloadButton() {
    const style = {
      "width": "2em",
      "height": "2em",
      "margin": "1em",
      "marginLeft": "2em"
    };
    return <img src="/images/download-icon.png" className="image-gallery-play-button" style={style} onClick={this.download}/>
  }
  
  render() {
    return (
      <div>
        <ImageGallery 
          ref = { 
            i => {
              this._imageGallery = i
            }
          }
          items={_images} 
          showPlayButton={false}
          showFullscreenButton={false}
          lazyLoad={true} 
          renderCustomControls={this._renderDownloadButton.bind(this)}
        />
      </div>
    );
  }
}

export default PhotoGallery;
