import React from 'react';
import "../css/WeddingDay.css";

class WeddingDay extends React.Component {

  render() {
    return (
      <div>
        <div className="wedding-day-image-wrapper">
          <img src="/images/wylie_inn.jpg" className="static-page-image" />
        </div>
        <div className="day-details" >
          lelkjaflkdf
        </div>
      </div>
    );
  }
}

export default WeddingDay;
