import React from 'react';
import "../css/Invitation.css";

class Invitation extends React.Component {

  render() {
    return (
      <div>
        <div className="invitation-image-wrapper">
          <img src="/images/invite.jpg" className="static-page-image" />
        </div>
      </div>
    );
  }
}

export default Invitation;
