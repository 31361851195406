import React from 'react';
import Countdown from 'react-countdown-now';

import '../css/Home.css';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {height: props.height, width: props.width};
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  updateDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight - 50});
  }
  componentWillMount(){
    this.updateDimensions();
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {

    const imageWidth = 4821;
    const imageHeight = 3214;

    // center coordinates
    const ratio = imageWidth / imageHeight;
    const isLandscape = (this.state.width / this.state.height) >= ratio;
    const imgHeight = isLandscape ? "auto" : this.state.height + "px";
    const imgwidth = isLandscape ? this.state.width + "px" : "auto";

    const bgStyles = {
      "width": imgwidth,
      "height": imgHeight,
    };

    const stdStyles = {
      // Easiest way to move std left/right is to reduce the width..
      "width": isLandscape ? "100%" : "100%",

      // transform to move vertically within app
      // "transform": "translateY(" + verticalTransform + "%)"
    };
    return (
      <div className="app">
        <img src="/images/engagement_photo_bg.jpg" className="Background-Img" style={bgStyles}/>
        <span className="std" style={stdStyles}>
          <h1>
            Save the Date
          </h1>
          <p>Kristen & Sean</p>
          <p>9-08-2019</p>
          <p>Beverly, MA</p>
          <Countdown 
            date={new Date(Date.UTC(2019, 8, 8, 20, 0, 0))} 
            renderer={props => 
              <span className="countdown" >
                {props.days} Days, {props.hours} hours, {props.minutes} minutes, {props.seconds} seconds
              </span>
            }
          />
        </span>
      </div>
    )
  }
}
export default Home;
