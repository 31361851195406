import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// This text field will actually pull from styles, but it screws everything else up..
// import TextField from '@material-ui/core/TextField';
import TextField from 'material-ui/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom'
import { isBrowser } from "react-device-detect";

import '../css/Rsvp.css';

// constants
const dinnerOptions = [
  {
    value: 'Grilled Beef Filet Mignon - Served on a roasted fingerling potatoes with garlic roasted demi-glace and sautéed French green beans', 
    label: 'Filet Mignon'
  },
  {
    value: 'Pistachio-Crusted Halibut - Saffron cream, served on top of a roasted fingerling potatoes and seasonal baby glazed carrots', 
    label: 'Pistachio-Crusted Halibut'
  },
  {
    value: 'Butternut Squash Ravioli - Tender ravioli filled with roasted butternut squash in a sage cream sauce with pecans', 
    label: 'Butternut Squash Ravioli'
  },
  {
    value: 'Chicken Fingers',
    label: 'Chicken Fingers (13 or under)'
  }
]

const attendingOptions = [
  {
    value: 'Yes',
    label: 'Yes'
  },
  {
    value: 'No',
    label: 'No'
  },
];

const charityOptions = [
  {
    value: 'St. Jude Children\'s Research Hospital',
    label: 'St. Jude Children\'s Research Hospital'
  },
  {
    value: 'NorthEast Animal Shelter',
    label: 'NorthEast Animal Shelter'
  }
]

const useStyles = makeStyles(theme => ({
  centeredFormSection: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  leftAlignedFormSection: {
    width: '100%',
    alignItems: 'left',
    justifyContent: 'left',
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(0.5),
    alignContent: 'center'
  },
  button: {
    margin: theme.spacing(0.5),
    color: 'white'
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    color: 'white'
  }
}));

const defaultValues = () => {
  return {
    firstName: {
      value: '',
      error: true
    },
    lastName: {
      value: '',
      error: true
    },
    attending: {
      value: attendingOptions[0].value,
      error: false
    },
    dinner: {
      value: dinnerOptions[0].value,
      error: false
    },
    allergies: {
      value: '',
      error: false
    },
    songs: {
      value: '',
      error: false
    },
    charity: {
      value: charityOptions[0].value,
      error: false
    }
  }
};

export default function Rsvp() {
  const classes = useStyles();

  // state
  const [count, setCount] = React.useState(1)
  const [firstTry, setFirstTry] = React.useState(true)
  const [errorCount, setErrorCount] = React.useState(2)
  const [submitting, setSubmitting] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(0)
  const [values, setValues] = React.useState({
      0: defaultValues()
    }
  );

  const validField = (fieldName, value) => {
    switch(fieldName) {
      case 'firstName':
        return (value ? true : false);
      case 'lastName':
        return (value ? true : false);
      default:
        return true;
    }
  }

  const updateField = (i, e) => {
    // console.log('Name: ' + e.target.name)
    // console.log('Value: ' + e.target.value)
    let prevError = values[i][e.target.name].error
    let newError = !validField(e.target.name, e.target.value)
    setValues({
      ...values,
      [i]: {
        ...values[i],
        [e.target.name]: {
          value: e.target.value,
          error: newError
        }
      }
    });
    // console.log('prevError: ' + prevError)
    // console.log('newError: ' + newError)

    if (newError != prevError) {
      if (newError) {
        console.log('Incrementing error count to: ' + (errorCount + 1))
        setErrorCount(errorCount + 1)
      } else {
        console.log('Decrementing error count to: ' + (errorCount - 1))
        setErrorCount(errorCount - 1)
      }
    }
  };
  
  // https://stackoverflow.com/questions/49625572/how-to-post-multipart-formdata-using-fetch-in-react-native
  const upload = async (url, data) => {
    let options = {
      method: 'POST',
      mode: 'no-cors'
    };
  
    options.body = new FormData();
    for (let key in data) {
      options.body.append(key, data[key]);
    }

    const response = await fetch(url, options);
    return true;
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFirstTry(false)
    if (errorCount > 0) {
      console.log('Invalid input. returning');
      return
    }
    
    let url = "https://docs.google.com/forms/d/e/1FAIpQLScBQ6rQ38UBIXvtaduadthg6YDI6MDFbcowvQRIPW5DzcRi2g/formResponse?embedded=true" 
    let submittedCount = 0
    setSubmitting(true)
    for (const i in Object.keys(values)) {
      let data = {
        // First name
        'entry.287111577': values[i].firstName.value,

        // Last name
        'entry.258096510': values[i].lastName.value,

        // Attending
        'entry.1498135098': values[i].attending.value,

        // Dinner..
        'entry.1853627468': values[i].dinner.value,

        // Allergies
        'entry.2001479175': values[i].allergies.value,

        // Song requests
        'entry.1385070873': values[i].songs.value,

        // Charity
        'entry.1066651620': values[i].charity.value
      } 
      const uploadResponse = await upload(url, data);

      if (uploadResponse) {
        submittedCount = submittedCount + 1
        console.log('Incrememnting submitted to: ' + (submittedCount))
      }
    }
    setSubmitted(submittedCount)
    setSubmitting(false)
  }
  
  const countErrors = (person) => {
    let errors = 0
    for (const field in person) {
      if (person[field].error) {
        errors = errors + 1
      }
    }
    return errors
  }

  const addForm = async event => {
    let newPerson = defaultValues()
    let newCount = count + 1
    let newErrorCount = errorCount + countErrors(newPerson)
    
    console.log('New count: ' + newCount)
    console.log('New error count: ' + newErrorCount)
    setValues({
      ...values,
      [newCount - 1]: defaultValues()
    });
    setCount(newCount)
    setErrorCount(newErrorCount)
  }

  const removeForm = async event => {
    // need to determine how many errors are being removed by deleting this form..
    let newCount = count - 1
    let newErrorCount = errorCount - countErrors(values[newCount])
    let stateCopy = values
    delete stateCopy[newCount]
    setValues(stateCopy);
    setCount(newCount)
    setErrorCount(newErrorCount)
  }

  const formWidth = '75%';
  const formStyle = {
    borderImage: 'linear-gradient(45deg, #3acfd5 0%, #030b1f 100%) 1',
    // Below is the color of the light blue highlight. tbd what is better..
    // borderImage: 'linear-gradient(45deg, #00bbd4 0%, #030b1f 100%) 1',
    margin: '10px', 
    borderTop: '10px solid', 
    WebkitBorderRadius: '20px',
    borderRadius: '20px', 
    borderColor: '#030b1f',
    width: formWidth,
    display: 'inline-block'
  }

  const baseFloatingLabelStyle = {
  }

  return (
    // browser class gets zoomed in. No custom css for mobile
    <div className={isBrowser ? "rsvp-div-browser" : "rsvp-div-mobile"}>
      <div>
        <h1 className={classes.centeredFormSection}>
          RSVP
        </h1>
        <p className={classes.centeredFormSection}>
          Please fill out one form per person by August 24th.
        </p>
      </div>
      <div className={classes.centeredFormSection}>
        <p style={{textAlign: 'center', width: formWidth}}>
          In lieu of a traditional wedding favor, we will donate to either St. Jude or Northeast Animal Shelter on your behalf. 
        </p>
      </div>
      {[...Array(count).keys()].map((i) => 
        <div key={'form-' + i} className="formDiv" style={{textAlign: "center"}}> 
          <form autoComplete="off" className="rsvp-form" style={formStyle}>            
            <div className={classes.centeredFormSection}>
              <FormControl className={classes.formControl} style={{width: "100%"}}>
                <TextField
                  label="First Name"
                  autoFocus
                  floatingLabelText="First Name"
                  className={classes.textField}
                  value={values[i].firstName.value}
                  onChange={(e) => updateField(i, e)}
                  name="firstName"
                  margin="normal"
                  variant="filled"
                  style={{width: "100%"}}
                  floatingLabelStyle={
                    ((!firstTry) && values[i].firstName.error) ? 
                    {
                      ...baseFloatingLabelStyle,
                      color: '#F01E09'
                    } :
                    baseFloatingLabelStyle
                  }
                />
              </FormControl>
              <FormControl className={classes.formControl} style={{width: "100%"}}>
                <TextField
                  label="Last Name"
                  floatingLabelText="Last Name"
                  className={classes.textField}
                  value={values[i].lastName.value}
                  onChange={(e) => updateField(i, e)}
                  name="lastName"
                  margin="normal"
                  variant="filled"
                  style={{width: "100%"}}
                  floatingLabelStyle={
                    ((!firstTry) && values[i].lastName.error) ? 
                    {
                      ...baseFloatingLabelStyle,
                      color: '#F01E09'
                    } :
                    baseFloatingLabelStyle
                  }
                />
              </FormControl>
            </div>
            <div className={classes.centeredFormSection}>
              <FormControl className={classes.formControl} style={{width: "100%"}}>
                <InputLabel htmlFor="attending-input" style={{fontSize: "1em"}}>Attending</InputLabel>
                <Select
                  name="attending"
                  value={values[i].attending.value}
                  onChange={(e) => updateField(i, e)}
                  style={{width: "100%"}}
                >
                  {attendingOptions.map(option => 
                    <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} style={{width: "100%"}}>
                <InputLabel htmlFor="dinner-input" style={{fontSize: "1em"}}>Dinner</InputLabel>
                <Select
                  name="dinner"
                  value={values[i].dinner.value}
                  onChange={(e) => updateField(i, e)}
                  style={{width: "100%"}}
                >
                  {dinnerOptions.map(option => 
                    <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>
                  )}
                  </Select>
              </FormControl>
              <FormControl className={classes.formControl} style={{width: "100%"}}>
                <InputLabel htmlFor="charity-input" style={{fontSize: "1em"}}>Charity</InputLabel>
                <Select
                  name="charity"
                  value={values[i].charity.value}
                  onChange={(e) => updateField(i, e)}
                  style={{width: "100%"}}
                >
                  {charityOptions.map(option => 
                    <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className={classes.centeredFormSection}>
              <FormControl className={classes.formControl} style={{width: "100%"}}>
                <TextField
                  floatingLabelText="Allergies"
                  className={classes.textField}
                  value={values[i].allergies.value}
                  errorText={(!firstTry) && values[i].allergies.error && "Fill in allergies"}
                  onChange={(e) => updateField(i, e)}
                  name="allergies"
                  variant="filled"
                  style={{width: "100%"}}
                  floatingLabelStyle={
                    ((!firstTry) && values[i].allergies.error) ? 
                    {color: 'red'} :
                    null
                  }
                />
              </FormControl>
            </div>
            <div className={classes.centeredFormSection}>
              <FormControl className={classes.formControl} style={{width: "100%"}}>
                <TextField
                  className={classes.textField}
                  floatingLabelText="Song Request"
                  value={values[i].songs.value}
                  errorText={(!firstTry) && values[i].songs.error && "Fill in songs"}
                  onChange={(e) => updateField(i, e)}
                  name="songs"
                  variant="filled"
                  style={{width: "100%"}}
                  floatingLabelStyle={
                    ((!firstTry) && values[i].songs.error) ? 
                    {color: 'red'} :
                    null
                  }
                />
              </FormControl>
            </div>
          </form>
        </div>
      )}
      <div className={classes.centeredFormSection} style={{marginBottom: '12px'}}>
        <Button 
          disabled={submitting}
          variant="contained" 
          className={classes.button} 
          onClick={addForm}
          style={{background: submitting ? '#e0e0e0' : '#030b1f'}}
          
        >
          Add   
          <AddIcon className={classes.rightIcon} />
        </Button>
        <Button 
          disabled={count <= 1 || submitting}
          variant="contained" 
          className={classes.button} 
          onClick={removeForm}
          style={{background: (count <= 1 || submitting) ? '#e0e0e0' : '#030b1f'}}
        >
          Remove   
          <DeleteIcon className={classes.rightIcon}/>
        </Button>
        <Button 
          disabled={((!firstTry) && (errorCount > 0) || submitting)}
          variant="contained" 
          className={classes.button} 
          onClick={handleSubmit}
          style={{background: ((!firstTry) && (errorCount > 0) || submitting) ? '#e0e0e0' : '#030b1f'}}
        >
          Submit
          {
            submitting ? 
            <CircularProgress size={12} className={classes.rightIcon} /> :
            <SaveIcon className={classes.rightIcon} style={{color: 'white'}}/>
          }
          {(submitted >= count && !submitting) && <Redirect to='/wedding'/> }
        </Button>
      </div>
    </div>
  );
}
