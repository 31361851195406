import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './Home';
import WeddingParty from './WeddingParty';
import PhotoGallery from './PhotoGallery';
import Rsvp from './Rsvp'
import GoogleForm from './GoogleForm'
import Contacts from './Contacts'
import Accommodations from './Accommodations'
import WeddingDay from './WeddingDay'
import Invitation from './Invitation'
import FamilyPhotos from './FamilyPhotos';

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

const Main = () => (
  <main>
    <Switch>
      {/* '/' and '/home' should redirect to '/wedding' */}
      <Route exact path='/' render={() => (
          <Redirect to="/wedding"/>
        )}/>
      <Route exact path='/home' render={() => (
          <Redirect to="/wedding"/>
        )}/>
      <Route exact path='/wedding' component={Home}/>
      <Route exact path='/wedding/party' component={WeddingParty}/>
      <Route exact path='/wedding/photos' component={PhotoGallery}/>
      <Route exact path='/wedding/rsvp' component={Rsvp}/>
      <Route exact path='/wedding/googleForm' component={GoogleForm}/>
      <Route exact path='/wedding/contacts' component={Contacts}/>
      <Route exact path='/wedding/info' component={Accommodations}/>
      <Route exact path='/wedding/day' component={WeddingDay}/>
      <Route exact path='/wedding/invitation' component={Invitation}/>
      <Route exact path='/wedding/family' component={FamilyPhotos}/>
      <Route path="*" component={NoMatch}/>
    </Switch>
  </main>
)

export default Main;
