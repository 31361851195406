
import React from 'react';
import NavBar from './NavBar'
import Main from './Main'

// this component will be rendered by our <___Router>
const App = () => (
    <div>
      <NavBar />
      <Main />
    </div>
  )
export default App;
